body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.hoverHP{
  margin-left:5px;
  margin-right:5px;
  padding-left:5px;
  padding-right:5px;
}
.hoverHP:hover{
  background-color: rgba(0,0,0,0.6);
}
*[contenteditable]{
  border-bottom: 3px solid #fff
}
.card{
  border: 2px solid #fff;
  color: white;
  padding: 10px;
  margin: 10px
}
.card>h3{
  padding-top:0px;
  margin-top:0px;
}

.tag {
  padding: 5px;
  background-color: #fff;
  color:#1c2329;
  border-radius: 10px;
  font-weight: bold;
}

.butt-ton{
  border: 1px solid #fff;
  color: white;
  padding: 5px 10px;
  margin: 5px;
  line-height: 30px;
  height: 30px;
}
.butt-ton:hover{
  color: #1c2329;
  background-color: #fff;
  cursor: pointer;
}

.outerLB{
  position: absolute;

  left: 0;
  right: 0;
  top:0;
  bottom:0;
  background-color: rgba(0,0,0,0.6);
}

.innerLB{
  color: #fff;
  background-color:  #1c2329;
  border: 5px solid #fff;
  width: 600px;
  padding: 15px;
  margin: 100px auto;
}
.none{
  display: none;
}

pre {
  color: #ffffff;
  font-family: 'Lato', sans-serif;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}